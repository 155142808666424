$(function() {

  /**
   * ページ内リンクをスムーススクロールで移動します。
   * `<body>`タグに`height: 100%;`、または`overflow: hidden`が指定されている場合は正常に動作しません。
   * https://cferdinandi.github.io/smooth-scroll/options.html
   * 例：ページトップへ戻る
   * <a class="js-smoothScroll" href="#">トップに戻る</a>
   *
   * 例：id属性が指定してある要素まで移動する
   * <header class="sw-Header"></header>
   * <a class="js-smoothScroll" href="#section1">○○へ移動</a>
   * <div id="section1">○○</div>
   */
  document.addEventListener('DOMContentLoaded', function() {
      var scroller = new SweetScroll({
        trigger: '.js-smoothScroll', // トリガーとなる要素をCSSセレクタで指定
        header: '.st-TitleB', // 固定ヘッダをCSSセレクタで指定
        duration: 500, // アニメーション再生時間のミリ秒
        easing: 'easeInOutCubic', // イージングのタイプ
        offset: 0, // スクロール位置のオフセット
        vertical: true, // 垂直方向のスクロールを許可する
        horizontal: false, // 水平方向のスクロールを許可する
        cancellable: true, // ホイールやタッチイベント発生時にスクロールを停止する
        updateURL: true, // スクロール完了後にURLを更新する
        preventDefault: true, // コンテナ要素のクリックイベントを防止する
        stopPropagation: true, // コンテナ要素のバブリングを防止する
        quickMode: false // 目的地まで素早く移動する (`easeOutExpo`を推奨)
    });


    /**
     * ページ読み込み時にURLのハッシュと同じid属性があれば、その要素まで移動する。
     */
    var locationHash = window.location.hash;
    var hashExists = locationHash.length >= 1;

    if (!hashExists) return;

    if (hashExists) {
      window.location.hash = '';
    }

    window.addEventListener('load', function() {
      var scrollElement = document.getElementById(locationHash.substr(1));
      var needsInitialScroll = scrollElement != null;

      if (needsInitialScroll) {
        scroller.to(locationHash, { updateURL: 'replace' });
      }
    });

  }, false);

}());
