$(function() {

  function homeSlider() {
    $('.home-Hero ul').bxSlider({
      mode: 'fade',
      controls: false,
      auto: true,
      autoHover: true,
      pagerCustom: '.home-Hero_ThumbailArea'
    });

    $('.home-Hero_ThumbailImage').hover(function() {
      $(this).prev('.home-Hero_ThumbailSummary').show();
    }, function() {
      $(this).prev('.home-Hero_ThumbailSummary').hide();
    });
  }

  if($('.home-Hero').length) {
    homeSlider();
  }

});
