$(function() {

  /**
   * 表示中のページとサイドバー内のサブメニューのリンク先が同じ場合、
   * カレント表示をする。
   */
  if($('.sw-SideMenu_Link').length) {
    $('.sw-SideMenu_Link').each(function() {
      var url = location.pathname;
      if($(this).attr('href') == url ) {
        $(this).addClass('sw-SideMenu_Link-current');
      }
    });
  }

}());
