$(function() {
  if($('.home-Network_About, .home-Network_Other').length) {
    $('.home-Network_About, .home-Network_Other').matchHeight();
  }

  if($('.home-Network_OtherNetwork').length) {
    $('.home-Network_OtherNetwork').matchHeight();
  }

}());
